import * as React from 'react';
import {Avatar, Icon} from 'antd';
import LogoIcon from '../../assets/Logo.png';
import * as styles from './style.module.scss';
import {Button} from 'antd';

type HeaderProps = {
  signOut: any,
};

const Header = ({signOut}: HeaderProps) => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerContent}>
        <div className={styles.logo}>
          <img src={LogoIcon} alt="Logo" />
        </div>
        <div className={styles.headerContentRight}>
          <Avatar icon={<Icon type="user" />} />
          <Button className={styles.userName} onClick={signOut}>
            Sign Out
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
