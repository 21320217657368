import React from 'react';
import {Input} from 'antd';

type TitleSearchProps = {
  onSearch: any,
  searchText: String,
  onChange: any,
};

const Search = Input.Search;

export const TitleSearch = ({
  onSearch,
  searchText,
  onChange,
  ...props
}: TitleSearchProps) => (
  <div {...props}>
    <Search
      placeholder="Enter Name"
      onSearch={onSearch}
      style={{width: 200, borderRadius: '5px'}}
      value={searchText}
      onChange={onChange}
    />
  </div>
);
