/*eslint-disable*/ 
import React from 'react';
import { SignUpForm } from '../../components';

type Props = {
  match: Props,
};

const SignUp = ({ match }: Props) => {
  const username =
    match.params && match.params.username ? match.params.username : null;
  const code = match.params && match.params.code ? match.params.code : null;
  return <SignUpForm username={username} code={code} />;
};

export default SignUp;
