import React from 'react';
// import ReactGA from 'react-ga';
import {Switch, Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
// import Tnc from './tnc.js';

import * as Views from './views';
import UserFlowLayout from './views/UserFlowLayout';
import DefaultLayout from './views/DefaultLayout';
// import DefaultLayout from './views/DefaultLayout';

type Props = {
  authState?: string,
};

const history = createBrowserHistory();
// ReactGA.initialize('UA-150718422-1');
// ReactGA.pageview(history.location.pathname);
// history.listen((location, action) => {
//   ReactGA.pageview(location.pathname);
// });
const Routes = ({authState, ...otherProps}: Props) => {
  console.log('authState', authState);  // eslint-disable-line
  if (authState !== 'signedIn') {
    return (
      <Router history={history}>
        <Switch>
          <UserFlowLayout
            exact
            path="/"
            component={Views.Login}
            {...otherProps}
          />
          <UserFlowLayout path="/signup" component={Views.SignUp} />
          <UserFlowLayout
            path="/verify/:username/:code"
            component={Views.SignUp}
          />
          <UserFlowLayout
            path="/forgot-password/:username/:code"
            component={Views.ForgotPassword}
          />
          <UserFlowLayout
            path="/forgot/password"
            component={Views.ForgotPassword}
          />
        </Switch>
      </Router>
    );
  } else {
    return (
      <Router history={history}>
        <Switch>
          <DefaultLayout
            path="/profile"
            component={Views.Profile}
            authData={{
              attributes: {
                name: 'User',
              },
            }}
          />
          <DefaultLayout exact path="/" component={Views.Dashboard} />
          <DefaultLayout
            component={Views.DriverRequest}
            path="/drivers/request"
          />
          <DefaultLayout
            component={Views.DriverEarnings}
            path="/drivers/earnings"
          />
        </Switch>
      </Router>
    );
  }
};

export default Routes;
