/* @flow */
import React, {useState, useEffect} from 'react';
import {Row, Col, Table, Avatar} from 'antd';
import {Header} from '../../components';
import {SideBar} from '../../components';
import * as styles from './style.module.scss';
import {Button} from 'antd';
import {
  addDriver,
  getAllDrivers,
  editDriver,
} from '../../services/AdminService';
import AddDriver from '../../components/AddDriver';
import EditDriver from '../../components/EditDriver';
import {TitleSearch} from '../../components/Filters/TitleSearch';
import {Auth} from 'aws-amplify';

type Props = {
  form: any,
  history: Object,
};

const Dashboard = ({form, history}: Props) => {
  const stateExists = !!history.location?.state?.key;
  const [shouldAddDriver] = useState(stateExists);
  const [driverStatus, setDriverStatus] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [driverToRender, setDriverToRender] = useState({});
  const [isAddingDriver, setIsAddingDriver] = useState(shouldAddDriver);
  const [filteredDriversRender, setFilteredDriversRender] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const RenderNameAndImage = renderData => {
    return (
      <span
        style={{
          textAlign: 'center',
          paddingLeft: 15,
        }}
      >
        <span style={{color: '#8798ad'}}>{renderData.key + 1}.</span>
        &nbsp;&nbsp;
        <Avatar src={renderData.imagePath} /> &nbsp;&nbsp;
        <span style={{color: '#2e384d', fontWeight: '600'}}>
          {renderData.fullName}
        </span>
      </span>
    );
  };

  const signOut = () => {
    Auth.signOut();
  };

  const fullNameMaker = (driver, i) => {
    driver.renderData = {};
    driver.key = i;
    driver.renderData.fullName = `${driver.firstName} ${driver.lastName}`;
    driver.renderData.imagePath = driver.userImagePath;
    driver.renderData.key = i;
    return driver;
  };

  useEffect(() => {
    getAllDrivers()
      .then(response => {
        const formattedDrivers = [];
        response.data.drivers.forEach((driver, i) => {
          formattedDrivers.push(fullNameMaker(driver, i));
        });
        setDrivers(formattedDrivers);
        setFilteredDriversRender(formattedDrivers);
        setDriverToRender(formattedDrivers[0]);
        setDriverStatus(formattedDrivers[0].status === 'Active');
        setIsLoading(false);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('Error in useEffect Driver call: ', err);
        setIsLoading(false);
      });
  }, []);

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'renderData',
      key: 'renderData',
      render: RenderNameAndImage,
      width: '250px',
      align: 'left',
    },
    {
      title: 'MOBILE no.',
      dataIndex: 'phoneNumber',
      align: 'center',
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
    },
    // {
    //   title: 'No. of del',
    //   dataIndex: 'del',
    // },
    // {
    //   title: 'ACTIVE FROM',
    //   dataIndex: 'active',
    // },
    {
      title: 'STATUS',
      dataIndex: 'status',
    },
  ];
  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      name: [
        'Edgar Matthews',
        'https://www.aithinkers.com/wp-content/uploads/2019/09/diagram-direction-electronics-1305360-1-e1568387600909.jpg',
        i,
      ],
      // name: 'Joe Black',
      id: '#12345645',
      mobile: '+254 202 0377',
      del: '300',
      active: '3 Months',
      status: 'Active',
    });
  }

  function onChange(checked) {
    setDriverStatus(checked);
  }

  const onAddNewSubmit = async (
    firstName,
    lastName,
    phoneNumber,
    location,
    email,
    vehicleModel,
    vehicleMake,
    vehicleColor,
    vehicleLicensePlateNumber,
    bankName,
    accountNumber,
    routingNumber,
  ) => {
    const payload = {
      driverProfile: {
        firstName: String(firstName),
        lastName: String(lastName),
        phoneNumber: String(phoneNumber),
        email,
        vehicleModel: String(vehicleModel),
        vehicleMake: String(vehicleMake),
        vehicleColor: String(vehicleColor),
        vehicleLicensePlateNumber: String(vehicleLicensePlateNumber),
        status: driverStatus ? 'Active' : 'Inactive',
        location: location,
        bankName: String(bankName),
        accountNumber: String(accountNumber),
        routingNumber: String(routingNumber),
        driverRequestId: stateExists
          ? history.location.state.requestId
          : undefined,
      },
    };
    setIsLoading(true);
    addDriver(payload)
      .then((res: Object) => {
        getAllDrivers()
          .then(response => {
            const formattedDrivers = [];
            response.data.drivers.map((driver, i) => {
              formattedDrivers.push(fullNameMaker(driver, i));
            });
            setDrivers([...formattedDrivers]);
            setFilteredDriversRender([...formattedDrivers]);
            setDriverToRender(formattedDrivers[0]);
            setIsLoading(false);
            setIsAddingDriver(false);
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.log('Error at getAllDrivers after add drivers: ', error);
            setIsLoading(false);
          });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('Error in driver creation waah: ', error, error.response);
        alert(
          error?.response?.data?.message
            ? error.response.data.message
            : 'There was some issue while adding the driver to the database.' +
                ' Please check if you selected a location or entered correct information.',
        );
        setIsLoading(false);
      });
  };

  const onEditProfileSubmit = async (
    firstName,
    lastName,
    email,
    location,
    locationId,
    setIsEditMode,
    vehicleMake,
    vehicleModel,
    vehicleColor,
    vehicleLicensePlateNumber,
    bankName,
    accountNumber,
    routingNumber,
  ) => {
    const payload = {
      profile: {
        firstName: String(firstName),
        lastName: String(lastName),
        phoneNumber: driverToRender.phoneNumber,
        email: String(email),
        vehicleMake: String(vehicleMake),
        vehicleModel: String(vehicleModel),
        vehicleColor: String(vehicleColor),
        vehicleLicensePlateNumber: String(vehicleLicensePlateNumber),
        status: driverStatus ? 'Active' : 'Inactive',
        ratings: '5',
        locationId: String(locationId),
        location: location,
        bankName: String(bankName),
        accountNumber: String(accountNumber),
        routingNumber: String(routingNumber),
      },
    };

    editDriver(payload)
      .then((res: Object) => {
        getAllDrivers()
          .then(response => {
            const formattedDrivers = [];
            response.data.drivers.map((driver, i) => {
              formattedDrivers.push(fullNameMaker(driver, i));
            });
            setDrivers([...formattedDrivers]);
            setFilteredDriversRender([...formattedDrivers]);
            setDriverToRender(formattedDrivers[0]);
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.log('Error at getAllDrivers after add drivers: ', error);
          });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('Error in driver edit waah: ', error);
        alert(
          error?.response?.data?.message
            ? error.response.data.message
            : 'There was some issue while editing the driver to the database. Please check if you selected a location' +
                ' or entered correct information.',
        );
      });
    setIsEditMode(false);
  };

  const handleSearch = searchText => {
    const filteredEvents = drivers.filter(driver => {
      const title = driver.renderData.fullName.toLowerCase();
      return title.includes(searchText.toLowerCase());
    });

    setFilteredDriversRender(filteredEvents);
    setDriverToRender(filteredEvents.length > 0 ? filteredEvents[0] : {});
  };

  const onSearchChange = text => {
    setSearchText(text.target.value);
  };

  return (
    <div className={styles.dashboardContainer}>
      <Header signOut={signOut} />
      <div className={styles.dashboardContent}>
        <SideBar history={history} />

        <div className={styles.dashboardBody}>
          <Row>
            <Col xs={20} sm={20} md={20} lg={13} xl={17}>
              <div className={styles.searchAndTotal}>
                <h3>
                  Drivers <span>{drivers.length} Total</span>
                </h3>
                {/* <Input
                  placeholder="Search Drivers"
                  prefix={
                    <Icon type="search" style={{color: 'rgba(0,0,0,.25)'}} />
                  }
                  style={{
                    width: '200px',
                    borderRadius: '5px',
                  }}
                /> */}
                <TitleSearch
                  onSearch={handleSearch}
                  onChange={onSearchChange}
                  searchText={searchText}
                />
                <Button
                  className={styles.addNewBtn}
                  onClick={() => {
                    setFilteredDriversRender(drivers);
                    setDriverToRender(drivers.length > 0 ? drivers[0] : {});
                    setSearchText('');
                  }}
                >
                  Clear Filter
                </Button>
                <Button
                  className={styles.addNewBtn}
                  onClick={() => {
                    setIsAddingDriver(true);
                  }}
                >
                  Add New
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={20} sm={20} md={20} lg={13} xl={17}>
              <Table
                rowClassName={styles.driversTableRow}
                columns={columns}
                dataSource={filteredDriversRender}
                loading={isLoading}
                onRow={driver => {
                  return {
                    onClick: () => {
                      setIsAddingDriver(false);
                      setDriverToRender(driver);
                    },
                  };
                }}
              />
            </Col>
            <Col
              xs={20}
              sm={20}
              md={20}
              lg={7}
              xl={6}
              style={{marginLeft: '30px'}}
            >
              {isAddingDriver ? (
                <AddDriver
                  onSubmit={onAddNewSubmit}
                  driverStatus={driverStatus}
                  onDriverStatusChange={onChange}
                  initialState={history.location.state}
                />
              ) : (
                <>
                  {driverToRender && (
                    <EditDriver
                      driver={driverToRender}
                      onSubmit={onEditProfileSubmit}
                      onDriverStatusChange={onChange}
                      driverStatus={driverStatus}
                      history={history}
                    />
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
        {/*<h1>Dashboard</h1>
        Go to <Link to="/profile"> Profile </Link>
        <Button onClick={handleSignOut}>Log out</Button>*/}
      </div>
    </div>
  );
};

export default Dashboard;
