import React, {useState} from 'react';
import {Form, Input, Button} from 'antd/lib/index';
import {Link} from 'react-router-dom';
import {Auth} from 'aws-amplify/lib/index';
import * as styles from './style.module.scss';

type Props = {
  onStateChange: Function,
  form: {
    getFieldDecorator: Function,
    validateFields: Function,
  },
  onStateChange: Function,
  history: any,
};

const LogInForm = ({
  form: {getFieldDecorator, validateFields},
  onStateChange,
}: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [user, setUser] = useState({});

  const changePassword = e => {
    e.preventDefault();

    validateFields(async (err, {newPassword}) => {
      if (!err) {
        setLoading({isLoading: true});
        const data = await Auth.completeNewPassword(user, newPassword);
        onStateChange('signedIn', data);
      }
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFields(async (err, {username, password}) => {
      if (!err) {
        setLoading(true);
        const payload = {
          username: username.toLowerCase(),
          password,
        };
        Auth.signIn(payload)
          .then((res: Object) => {
            if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
              setShowConfirmPassword(true);
              setUser(res);
              setLoading(false);
            } else {
              onStateChange('signedIn', res);
            }
          })
          .catch(error => {
            setLoading(false);
            setError(error.message);
          });
      }
    });
  };

  return (
    <div className={styles.loginContainer}>
      <Form layout="vertical">
        <span style={{color: 'red'}}>{error}</span>
        <Form.Item label="Email" style={{marginBottom: '10px'}}>
          {getFieldDecorator('username', {
            rules: [
              {
                required: true,
                message: 'What is your email address?',
              },
            ],
          })(
            <Input
              id="username"
              key="username"
              name="username"
              placeholder="Enter Email"
              onChange={() => setError('')}
            />,
          )}
        </Form.Item>
        <Form.Item label="Password" style={{marginBottom: '15px'}}>
          {getFieldDecorator('password', {
            rules: [{required: true, message: 'What is your password?'}],
          })(
            <Input.Password
              id="password"
              key="password"
              name="password"
              placeholder="Enter Password"
              autoComplete="password"
              onChange={() => setError('')}
            />,
          )}
          <Link className={styles.forgotPasswordLink} to="/forgot/password">
            Forgot password?
          </Link>
        </Form.Item>
        {showConfirmPassword ? (
          <Form.Item label="New Password" style={{marginBottom: '15px'}}>
            {getFieldDecorator('newPassword', {
              rules: [{required: true, message: 'What is your new password?'}],
            })(
              <Input.Password
                id="newPassword"
                key="newPassword"
                name="newPassword"
                placeholder="Enter New Password"
                onChange={() => setError('')}
              />,
            )}
          </Form.Item>
        ) : null}
        <Button
          type="primary"
          style={{width: '120px', height: 45, fontSize: '18px'}}
          onClick={e =>
            showConfirmPassword ? changePassword(e) : handleSubmit(e)
          }
          loading={isLoading}
        >
          {showConfirmPassword ? 'Change Password' : 'Login'}
        </Button>
        {/*<div style={{marginTop: '1rem', fontSize: '14px'}}>
          Need an account?
          <Link to="/signup"> Create Account</Link>
        </div>*/}
      </Form>
    </div>
  );
};

export default Form.create({name: 'login'})(LogInForm);
