import * as React from 'react';
import {Route} from 'react-router-dom';
import {Layout as AntLayout} from 'antd';

type Props = {
  component: any,
};

class UserFlowLayout extends React.Component<Props, {collapsed: boolean}> {
  state = {
    collapsed: true,
  };
  props: Props;

  render() {
    const {component: Component, ...rest} = this.props;
    console.log(this.props, 'this.props') // eslint-disable-line
    return (
      <Route
        {...rest}
        render={matchProps => (
          <AntLayout style={{minHeight: '100vh', background: 'transparent'}}>
            <AntLayout
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'transparent',
              }}
            >
              <Component {...matchProps} {...this.props} />
            </AntLayout>
          </AntLayout>
        )}
      />
    );
  }
}

export default UserFlowLayout;
