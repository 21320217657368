/* @flow */
import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import {groupBy} from 'lodash/collection';
import moment from 'moment';
import {Table} from 'antd';
import * as styles from './style.module.scss';
import {Header, SideBar} from '../../components';

const columns = [
  {
    title: 'Period',
    dataIndex: 'period',
  },
  {
    title: 'Earning($)',
    dataIndex: 'value',
  },
];

type props = {
  history: Object,
};

const DriverEarnings = ({history}: props) => {
  const unorderedEarnings = history.location?.state?.earnings;
  if (!unorderedEarnings) {
    history.push('/');
  }
  const [earnings, setEarnings] = useState([]);

  useEffect(() => {
    const weeklyOrderedEarnings = groupBy(unorderedEarnings, item =>
      moment(item.updateDate).startOf('isoweek'),
    );
    setEarnings(
      Object.keys(weeklyOrderedEarnings).map((key, index) => ({
        key: index,
        period: `${moment(key).format('MMM DD YYYY')} to ${moment(key)
          .endOf('isoWeek')
          .format('MMM DD YYYY')}`,
        value: weeklyOrderedEarnings[key]
          .reduce(
            (value, item) =>
              value + (Number(item.driverShare) + Number(item.tip)),
            0,
          )
          .toFixed(2),
      })),
    );
  }, [unorderedEarnings]);

  const signOut = () => {
    Auth.signOut()
      // eslint-disable-next-line no-console
      .then(data => console.log(data))
      // eslint-disable-next-line no-console
      .catch(err => console.log(err));
  };

  const scroll = {x: 750, y: 500};

  return (
    <div className={styles.driverEarningsContainer}>
      <Header signOut={signOut} />
      <div className={styles.driverEarningsContent}>
        <SideBar history={history} />
        <div className={styles.driverEarningsBody}>
          <div className={styles.headerSectionBox}>
            <h3 className={styles.driverHeaderText}>Driver Earnings</h3>
          </div>
          {earnings.length > 0 && (
            <Table columns={columns} dataSource={earnings} scroll={scroll} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DriverEarnings;
