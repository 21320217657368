import React, {useState} from 'react';
import {Form, Input} from 'antd';
import {Auth} from 'aws-amplify';
import {Link} from 'react-router-dom';
import logo from '../../assets/bitmap.png';
import rightimg from '../../assets/loginbg.svg';

import Button from '../../components/Button';

import styles from './style.module.scss';

type Props = {
  form: Object,
  match: Object,
  history: Object,
};

const SellerForgotPassword = ({form, match}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState('');

  const code = match.params && match.params.code;
  const username = match.params && match.params.username;

  const checkPassword = (rule, value, callback) => {
    const {getFieldValue} = form;
        const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$') // eslint-disable-line
    const validPassword = regex.test(value);
    const password = getFieldValue('password');
    const confirmPassword = getFieldValue('confirmPassword');
    let message = '';
    if (password && confirmPassword && password !== confirmPassword)
      message = 'Password did not match!';
    if (!validPassword && value.length >= 8)
      message =
        'Password should contain one number, one special character one upper case letter.';
    if (!value) message = 'Please enter password';
    if (validPassword) message = '';
    message ? callback(message) : callback();
  };

  const handleSubmit = () => {
    const {validateFields} = form;
    validateFields((err, {email, password}) => {
      if (!code) {
        setIsLoading(true);
        Auth.forgotPassword(email.toLowerCase())
          .then(data => {
            setIsLoading(false);
            setSuccessMessage(true);
          })
          .catch(err => {
            setError(err.message);
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);
        Auth.forgotPasswordSubmit(username.toLowerCase(), code, password)
          .then(data => {
            setIsLoading(false);
            setSuccessMessage(true);
            // history.push('/admin');
          })
          .catch(err => {
            setError(err.message);
            setIsLoading(false);
          });
      }
    });
  };

  const {getFieldDecorator} = form;

  return (
    <div className={styles.forgetPasswordContainer}>
      <div className={styles.forgetPasswordContent}>
        <div className={styles.forgetPasswordLeft}>
          <img src={logo} alt="logo" />
          <div className={styles.forgetPasswordLeftContent}>
            {!showSuccessMessage && (
              <Form onSubmit={handleSubmit}>
                {!code && (
                  <div>
                    <h3>Enter your email address to get reset link.</h3>
                    <span style={{color: 'red'}}>{error}</span>
                    <Form.Item>
                      {getFieldDecorator('email', {
                        rules: [
                          {
                            required: true,
                            type: 'email',
                            message: 'Enter valid address',
                          },
                        ],
                      })(
                        <Input
                          onChange={() => setError('')}
                          placeholder="Enter email address"
                        />,
                      )}
                    </Form.Item>
                  </div>
                )}
                {code && (
                  <div>
                    <h3>Update your password here.</h3>
                    <span style={{color: 'red'}}>{error}</span>
                    <Form.Item>
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            validator: checkPassword,
                          },
                        ],
                      })(
                        <Input.Password
                          onChange={() => setError('')}
                          placeholder="Enter Password"
                        />,
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator('confirmPassword', {
                        rules: [
                          {
                            required: true,
                            validator: checkPassword,
                          },
                        ],
                      })(
                        <Input.Password
                          onChange={() => setError('')}
                          placeholder="Confirm Password"
                        />,
                      )}
                    </Form.Item>
                  </div>
                )}
                <Button
                  style={{width: '180px', height: 40}}
                  type="primary"
                  onClick={handleSubmit}
                  loading={isLoading}
                >
                  {code ? 'CHANGE PASSWORD' : 'GET RESET LINK'}
                </Button>
              </Form>
            )}

            {showSuccessMessage && (
              <div style={{height: 150}} className={styles.layout}>
                {!code && (
                  <p>
                    A verification link is sent to your email. Please click on
                    the link to update your password.
                  </p>
                )}
                {code && (
                  <div>
                    <p>
                      Your password has been updated successfully.
                      <Link to="/"> Sign In</Link>
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.forgetPasswordRight}>
          <img src={rightimg} alt="rightimg" />
        </div>
      </div>
    </div>
  );
};

export default Form.create({name: 'sellerForgotPassword'})(
  SellerForgotPassword,
);
