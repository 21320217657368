import React, {useState, useEffect} from 'react';
import {Row, Col, Select, Form, Input, Card, Spin, Checkbox} from 'antd';
import {Auth} from 'aws-amplify';
import {Button as AntButton} from 'antd';
import Tnc from '../../../src/tnc';
import styles from './style.module.css';
import {Link} from 'react-router-dom';
import {countryCodes} from '../../utils/countryCodes';
const {Option} = Select;
type Props = {
  form: Object,
  companyId: string,
  username: string,
  code: string,
};

const SignUpForm = ({form, username, code}: Props) => {
  const [isSignUp] = useState(!username);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showVerifyMessage, setVerify] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [isTncVisible, setIsTncVisible] = useState(false);
  const [acceptTnc, setAcceptTnc] = useState(false);
  const [acceptTncError, setAcceptTncError] = useState('');
  const [countryCode, setCountryCode] = useState('+1');

  useEffect(() => {
    async function verifyUser() {
      setLoading(true);
      Auth.confirmSignUp(username, code, {})
        .then(data => {
          setIsUserVerified(true);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          setError(error.message);
        });
    }
    if (username && code) {
      verifyUser();
    }
  }, [username, code]);

  const prefixSelector = form.getFieldDecorator('prefix', {
    initialValue: '+1',
  })(
    <Select onChange={code => setCountryCode(code)} style={{width: 70}}>
      {countryCodes.map(code => (
        <Option key={code.value} value={code.value}>
          {code.text}
        </Option>
      ))}
    </Select>,
  );

  const onSubmit = async e => {
    e.preventDefault();
    const {validateFields, resetFields} = form;
    if (!acceptTnc) {
      setAcceptTncError('Please accept terms of services');
    }
    validateFields(async (err, {password, name, email, phone_number}) => {
      if (!acceptTnc) {
        return false;
      }
      if (!err) {
        const payload = {
          username: email.toLowerCase(),
          password: password,
          attributes: {
            name: name,
            phone_number: `${countryCode}${phone_number}`,
          },
        };

        // adding company name to payload if entered
        setLoading(true);
        Auth.signUp(payload)
          .then(res => {
            setLoading(false);
            resetFields();
            setError('');
            setVerify(true);
          })
          .catch(error => {
            setError(error.message);
            setLoading(false);
          });
      }
    });
  };

  const checkPassword = (rule, value, callback) => {
    const {getFieldValue} = form;
    const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$') // eslint-disable-line
    const validPassword = regex.test(value);
    const password = getFieldValue('password');
    const confirmPassword = getFieldValue('confirmPassword');
    let message = '';
    if (password && confirmPassword && password !== confirmPassword)
      message = 'Password did not match!';
    if (!validPassword && value.length >= 8)
      message =
        'Password should contain one number, one special character one upper case letter.';
    if (!value) message = 'Please enter password';
    if (validPassword) message = '';
    message ? callback(message) : callback();
  };
  const checkPhone = (rule, value, callback) => {
    if (!value) {
      callback('Enter your phone number');
    }
    const regex = new RegExp('^!*([0-9]!*){10,}$') // eslint-disable-line
    const validPhone = regex.test(value);
    if (!validPhone) {
      callback('Invalid phone number');
    }
    callback();
  };

  const {getFieldDecorator} = form;

  return (
    <Card style={{width: 400, margin: '0 auto'}}>
      <Form layout="vertical">
        <h3>{username ? 'VERIFY' : 'REGISTER'}</h3>
        <span style={{color: 'red'}}>{error}</span>
        {!username && !showVerifyMessage && (
          <>
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item label="Name">
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: 'Enter your name',
                      },
                    ],
                  })(<Input placeholder="Enter Name" />)}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Email">
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        required: true,
                        type: 'email',
                        message: 'Enter your email.',
                      },
                    ],
                  })(
                    <Input
                      placeholder="example@xyz.com"
                      autoComplete="username email"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Password">
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        validator: checkPassword,
                        type: 'password',
                        required: true,
                      },
                    ],
                    initialValue: '',
                  })(
                    <Input.Password
                      placeholder="Password"
                      autoComplete="new-password"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Phone Number">
                  {getFieldDecorator('phone_number', {
                    rules: [
                      {
                        required: true,
                        validator: checkPhone,
                        // message: 'Enter your phone number.',
                      },
                    ],
                    initialValue: '',
                  })(
                    <Input
                      addonBefore={prefixSelector}
                      placeholder="1234567890"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Checkbox
                    checked={acceptTnc}
                    onChange={() => setAcceptTnc(!acceptTnc)}
                  >
                    I agree to the
                  </Checkbox>
                  <span
                    className={styles.tnc}
                    onClick={() => {
                      setIsTncVisible(true);
                      setAcceptTncError('');
                    }}
                  >
                    terms of services
                  </span>
                  {!acceptTnc && (
                    <p className={styles.error}>{acceptTncError}</p>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <AntButton
              type="primary"
              style={{width: '100%', height: 50}}
              onClick={e => onSubmit(e)}
              loading={isLoading}
            >
              CREATE ACCOUNT
            </AntButton>
          </>
        )}

        {!isSignUp && (
          <>
            {isLoading && <Spin />}
            {isUserVerified && 'Your account is verified now, please login'}
          </>
        )}

        {showVerifyMessage && (
          <div>
            Your account has been created. Please check your inbox and click on
            the verification link
          </div>
        )}
        <div style={{marginTop: '1rem', fontSize: '14px'}}>
          Already Registered?
          <Link to="/"> Sign In </Link>
        </div>
      </Form>
      <Tnc
        isVisible={isTncVisible}
        onAgree={() => {
          setAcceptTnc(true);
          setIsTncVisible(false);
        }}
        onCancel={() => setIsTncVisible(false)}
      />
    </Card>
  );
};

export default Form.create({name: 'signup'})(SignUpForm);
