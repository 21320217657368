import React from 'react';
import {Authenticator, SignIn as AwsSignIn} from 'aws-amplify-react';

import Routes from './Routes';

import authenticatorConfig from './config/authenticatorConfig';
import {Greetings} from 'aws-amplify-react';
import './App.css';
import './index.scss';

const App = Props => (
  <Authenticator
    hide={[AwsSignIn, Greetings]}
    amplifyConfig={authenticatorConfig}
  >
    <Routes />
  </Authenticator>
);

export default App;
