import React from 'react';
import {Link} from 'react-router-dom';

const Profile = () => {
  console.log('profile section'); // eslint-disable-line
  return (
    <>
      <Link to="/" className="btn dashboard">
        Go to DASHBOARD
      </Link>
      <h1>This is profile section</h1>
    </>
  );
};

export default Profile;
