/* eslint-disable no-console */
import React, {useState} from 'react';
import {Form, Avatar, Input, Button} from 'antd';
import Switch from 'react-switch';
import LocationSearchInput from '../LocationSearchInput';
import * as styles from './style.module.scss';
import addIcon from '../../assets/add.svg';
type Props = {
  form: any,
  onSubmit: any,
  driverStatus: Boolean,
  onDriverStatusChange: any,
  firstName: String,
  lastName: String,
  phoneNumber: String,
  location: Object,
  initialState: Object,
};

const AddDriver = ({
  form,
  onSubmit,
  driverStatus,
  onDriverStatusChange,
  initialState,
}: Props) => {
  const {getFieldDecorator} = form;
  const {REACT_APP_EXTENSION: phoneExtension} = process.env;
  const [location, setLocation] = useState('');
  const [driverLocation, setDriverLocation] = useState({});
  const [addDisabled, setAddDisabled] = useState(false);

  const validatePhone = (rule, value, callback) => {
    const regex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    const isValid = regex.test(value);
    let message;
    isValid ? (message = '') : (message = 'Invalid Phone Number!');
    message !== '' ? callback(message) : callback();
  };

  const onSubmitHandler = e => {
    e.preventDefault();
    const {validateFields} = form;
    validateFields(
      async (
        err,
        {
          firstName,
          lastName,
          phoneNumber,
          email,
          vehicleModel,
          vehicleMake,
          vehicleColor,
          vehicleLicensePlateNumber,
          bankName,
          accountNumber,
          routingNumber,
        },
      ) => {
        setAddDisabled(true);
        onSubmit(
          firstName,
          lastName,
          phoneNumber,
          driverLocation,
          email,
          vehicleModel,
          vehicleMake,
          vehicleColor,
          vehicleLicensePlateNumber,
          bankName,
          accountNumber,
          routingNumber,
        );
      },
    );
  };

  return (
    <div>
      <h3 className={styles.addDriverText}>Add New Driver</h3>
      <div className={styles.addDriverSection}>
        {/*<Icon className={styles.close} type="close" />*/}
        <div>
          <Avatar size={80} icon="user" style={{border: '1.5px solid red'}} />
          <img alt="addIcon" src={addIcon} />
        </div>
        <Form className="login-form" onSubmit={onSubmitHandler}>
          <Form.Item label="First Name">
            {getFieldDecorator('firstName', {
              rules: [
                {
                  required: true,
                  message: 'Please Enter First Name!',
                },
              ],
              initialValue: initialState?.firstName,
            })(
              <Input
                id="firstName"
                key="firstName"
                name="firstName"
                placeholder="Enter First Name"
              />,
            )}
          </Form.Item>
          <Form.Item label="Last Name">
            {getFieldDecorator('lastName', {
              rules: [
                {
                  required: true,
                  message: 'Please Enter Name!',
                },
              ],
              initialValue: initialState?.lastName,
            })(
              <Input
                id="lastName"
                key="lastName"
                name="lastName"
                placeholder="Enter Last Name"
              />,
            )}
          </Form.Item>
          <Form.Item label="Phone Number">
            {getFieldDecorator('phoneNumber', {
              rules: [
                {
                  required: true,
                  validator: validatePhone,
                  message: 'Please Enter A Valid Phone Number!',
                },
              ],
              initialValue: initialState
                ? `+${phoneExtension}${initialState?.phone?.replaceAll(
                    '-',
                    '',
                  )}`
                : undefined,
            })(
              <Input
                id="phoneNumber"
                key="phoneNumber"
                name="phoneNumber"
                placeholder="Enter Phone Number"
              />,
            )}
          </Form.Item>
          <Form.Item label="Email">
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: 'Please Enter A Valid Email!',
                },
              ],
              initialValue: initialState?.email,
            })(
              <Input
                // type="number"
                id="email"
                key="email"
                name="email"
                placeholder="Enter Email"
              />,
            )}
          </Form.Item>
          <Form.Item label="Vehicle Make">
            {getFieldDecorator('vehicleMake', {
              rules: [
                {
                  required: true,
                  message: 'Please Enter Vehicle Make!',
                },
              ],
              initialValue: initialState?.make,
            })(
              <Input
                id="vehicleMake"
                key="vehicleMake"
                name="vehicleMake"
                placeholder="Enter Vehicle Make"
              />,
            )}
          </Form.Item>
          <Form.Item label="Vehicle Model">
            {getFieldDecorator('vehicleModel', {
              rules: [
                {
                  required: true,
                  message: 'Please Enter Vehicle Model!',
                },
              ],
              initialValue: initialState?.model,
            })(
              <Input
                id="vehicleModel"
                key="vehicleModel"
                name="vehicleModel"
                placeholder="Enter Vehicle Model"
              />,
            )}
          </Form.Item>
          <Form.Item label="Vehicle Color">
            {getFieldDecorator('vehicleColor', {
              rules: [
                {
                  required: true,
                  message: 'Please Enter Vehicle Color!',
                },
              ],
              initialValue: initialState?.color,
            })(
              <Input
                id="vehicleColor"
                key="vehicleColor"
                name="vehicleColor"
                placeholder="Enter Vehicle Color"
              />,
            )}
          </Form.Item>
          <Form.Item label="Vehicle License Plate Number">
            {getFieldDecorator('vehicleLicensePlateNumber', {
              rules: [
                {
                  required: true,
                  message: 'Please Enter Vehicle License Plate Number!',
                },
              ],
              initialValue: initialState?.licenseplatenumber,
            })(
              <Input
                id="vehicleLicensePlateNumber"
                key="vehicleLicensePlateNumber"
                name="vehicleLicensePlateNumber"
                placeholder="Enter Vehicle License Plate Number"
              />,
            )}
          </Form.Item>
          <Form.Item label="Bank Name">
            {getFieldDecorator('bankName', {
              rules: [
                {
                  required: true,
                  message: 'Please Enter the Bank Name!',
                },
              ],
              initialValue: initialState?.bankName,
            })(
              <Input
                id="bankName"
                key="bankName"
                name="bankName"
                placeholder="Enter the Bank Name"
              />,
            )}
          </Form.Item>
          <Form.Item label="Account Number">
            {getFieldDecorator('accountNumber', {
              rules: [
                {
                  required: true,
                  message: 'Please Enter the Account Number!',
                },
              ],
              initialValue: initialState?.accountNumber,
            })(
              <Input
                id="accountNumber"
                key="accountNumber"
                name="accountNumber"
                placeholder="Enter Account Number"
              />,
            )}
          </Form.Item>
          <Form.Item label="Routing Number">
            {getFieldDecorator('routingNumber', {
              rules: [
                {
                  required: true,
                  message: 'Please Enter Routing Number!',
                },
              ],
              initialValue: initialState?.routingNumber,
            })(
              <Input
                id="routingNumber"
                key="routingNumber"
                name="routingNumber"
                placeholder="Enter Routing Number"
              />,
            )}
          </Form.Item>
          <Form.Item label="Location" required>
            <LocationSearchInput
              initialAddress={initialState?.address}
              location={location}
              setLocation={setLocation}
              setDriverLocation={setDriverLocation}
            />
          </Form.Item>
          <Form.Item label="Status">
            {getFieldDecorator('status', {
              rules: [
                {
                  required: false,
                  // message: 'Please Select Driver Status!',
                },
              ],
            })(
              // <div className={styles.activeSection}>
              //   {/* <span className={styles.actionSectionText}>Active</span> */}
              //   <Switch
              //     defaultChecked
              //     onChange={onChange}
              //     checkedChildren="Active"
              //     unCheckedChildren="Inactive"
              //   />
              // </div>,
              <div className={styles.statusBar}>
                <span>{driverStatus ? 'Active' : 'Inactive'}</span>
                <Switch
                  checked={driverStatus}
                  onChange={onDriverStatusChange}
                  onColor="#36ae47"
                  onHandleColor="#36af47"
                  handleDiameter={15}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={7}
                  width={23}
                />
              </div>,
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={addDisabled}
            >
              Add New Driver
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Form.create({name: 'addDriver'})(AddDriver);
