import {api, secureApi} from '../utils/xhr';
import {API_URLS} from '../utils/constants';
import type {SignedURL} from '../utils/type';

export const getFileUploadSignedUrl = async (
  request: Object,
): Promise<SignedURL> => {
  try {
    const data: SignedURL = await api.get(API_URLS.dev.FILE_UPLOAD_SIGNEDURL, {
      params: request,
    });
    return data;
  } catch (err) {
    console.log(err); //eslint-disable-line
    throw err;
  }
};

export const uploadFile = async ({
  file,
  uploadUrl,
}: Object): Promise<Object> => {
  try {
    const data: Object = await api.put(
      uploadUrl,

      file,
      {
        headers: {
          'Content-Type': file.type,
        },
      },
    );
    return data;
  } catch (err) {
      console.log(err); //eslint-disable-line
    throw err;
  }
};

export const getDataByDate = async (date: string): Promise<SignedURL> => {
  try {
    const data: SignedURL = await api.get(API_URLS.dev.GET_ALL_DATA, {
      params: {date},
    });
    return data;
  } catch (err) {
    console.log(err); //eslint-disable-line
    throw err;
  }
};

export const addDriver = async (payload: Object): Promise<Object> => {
  try {
    const data: Object = await secureApi.post(API_URLS.dev.ADD_DRIVER, payload);
    return data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Error while adding driver: ', err);
    throw err;
  }
};

export const getAllDrivers = async (): Promise<SignedURL> => {
  try {
    const data: SignedURL = await secureApi.get(API_URLS.dev.GET_ALL_DRIVERS);
    return data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Error in getAllDrivers service: ', err);
    throw err;
  }
};

export const editDriver = async (payload: Object): Promise<Object> => {
  try {
    const data: Object = await secureApi.put(API_URLS.dev.EDIT_DRIVER, payload);
    return data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Error in Edit Driver API Call: ', err);
    throw err;
  }
};

export const getAllDriverSignupRequests = async (): Promise<Object> => {
  try {
    const data: Object = await secureApi.get(
      API_URLS.dev.GET_DRIVER_SIGNUP_REQUESTS,
    );
    // eslint-disable-next-line no-console
    console.log('data', data);
    return data.data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Error in getAllDrivers service: ', err);
    throw err;
  }
};
