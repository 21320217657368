import * as React from 'react';
import {Button as AntButton} from 'antd';
import classname from 'classnames';

import styles from './styles.module.css';

type Props = {
  type?: 'primary' | 'dashed' | 'danger' | 'default',
  size?: 'large' | 'small',
  className?: string,
  children?: React.Node,
};

const Button = ({
  type,
  size = 'small',
  className,
  children,
  ...otherProps
}: Props) => {
  return (
    <AntButton
      className={classname(className, styles.button, type ? styles[type] : '')}
      type={type}
      size={size}
      {...otherProps}
    >
      {children}
    </AntButton>
  );
};

export default Button;
