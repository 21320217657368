import axios from 'axios';
import {Auth} from 'aws-amplify/lib/index';

export const api = axios.create({
  baseURL: '',
  timeout: 60 * 1000,
});

export const secureApi = axios.create({
  baseURL: '',
  timeout: 60 * 1000,
});

/*eslint-disable*/

const logRequest = (request: 'object') => {
  const { method, baseURL, url, headers, params, data } = request;
  console.log('API', 'Request', method, baseURL, url, headers, params, request)
};

const handleSecureRequest = async (config: Object) => {
  const session = await Auth.currentSession();
  const accessToken = session.getIdToken();
  const { headers } = config;
  if (accessToken) {
    // added accessToken to header
    headers.Authorization = `Bearer ${accessToken.jwtToken}`
  }
  headers['Content-Type'] = 'application/json; multipart/form-data; charset=utf-8';
  console.log('config', config);
  // ; multipart/form-data; charset=utf-8
  // console.log(accessToken, 'accessToken')

  // logRequest(config)
  return config
};

const handleResponse = (response: Object) => {
  const { data, status, config } = response;
  logRequest(config)
  // console.log('API', 'Response', data, status, config)
  return response;
};

const handleRequestError = (error: Object) => {
  console.log('API - Error in handle request', error);
  // throw error
};

const handleResponseError = (error: Object) => {

  console.log('error in response', error.response)
  if (error.response && (error.response.status === 403 || error.response.status === 401)) {
    console.log('API - Token Expired - signing out user');
    // TODO: Redirect to login
  }

  console.log('API - Error', error, error.reponse?error.response.status:'');
  console.log("ERRROR RESPONSE", error.response);
  throw error
};

secureApi.interceptors.request.use(handleSecureRequest, handleRequestError);
secureApi.interceptors.response.use(handleResponse, handleResponseError);
const handleAuthRequest = (config: 'object') => {
  logRequest(config);
  return config
};
api.interceptors.request.use(handleAuthRequest, handleRequestError);
api.interceptors.response.use(handleResponse, handleResponseError);
