import React, {useEffect, useState} from 'react';
import {Form, Avatar, Input, Button} from 'antd';
import Switch from 'react-switch';
import LocationSearchInput from '../LocationSearchInput';
import * as styles from './styles.module.scss';
import addIcon from '../../assets/add.svg';

type Props = {
  form: any,
  onSubmit: any,
  driverStatus: Boolean,
  onDriverStatusChange: any,
  firstName: String,
  lastName: String,
  phoneNumber: String,
  location: Object,
  driver: Object,
  location: Object,
  history: any,
};

const EditDriver = ({
  form,
  onSubmit,
  driverStatus,
  onDriverStatusChange,
  driver,
  history,
}: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const {getFieldDecorator} = form;
  const [location, setLocation] = useState('');
  const [driverLocation, setDriverLocation] = useState(driver.location);
  const [editDisabled, setEditDisabled] = useState(false);

  useEffect(() => {
    setDriverLocation(driver.location);
  }, [driver.location]);

  const validatePhone = (rule, value, callback) => {
    const regex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    const isValid = regex.test(value);
    let message;
    isValid ? (message = '') : (message = 'Invalid Phone Number!');
    message !== '' ? callback(message) : callback();
  };

  const onSubmitHandler = e => {
    e.preventDefault();
    setEditDisabled(true);
    const {validateFields} = form;
    validateFields(
      async (
        err,
        {
          firstName,
          lastName,
          email,
          vehicleMake,
          vehicleModel,
          vehicleColor,
          vehicleLicensePlateNumber,
          bankName,
          accountNumber,
          routingNumber,
        },
      ) => {
        onSubmit(
          firstName,
          lastName,
          email,
          driverLocation,
          driver.location.id,
          setIsEditMode,
          vehicleMake,
          vehicleModel,
          vehicleColor,
          vehicleLicensePlateNumber,
          bankName,
          accountNumber,
          routingNumber,
        );
      },
    );
  };

  return (
    <div>
      <h3 className={styles.addDriverText}>Driver Details</h3>
      <div className={styles.addDriverSection}>
        <span
          onClick={() => {
            setIsEditMode(!isEditMode);
          }}
          className={!isEditMode ? styles.editProfile : styles.goBack}
        >
          {isEditMode ? 'Go Back' : 'Edit Profile'}
        </span>
        <span
          onClick={() => {
            history.push('/drivers/earnings', {earnings: driver.earnings});
          }}
          className={styles.viewEarnings}
        >
          View Earnings
        </span>
        {isEditMode ? (
          <div>
            {/*<Icon className={styles.close} type="close" />*/}
            <div className={styles.avatarBlock}>
              <Avatar
                size={80}
                icon="user"
                style={{border: '1.5px solid red'}}
              />
              <img alt="addIcon" src={addIcon} />
            </div>
            <Form className="login-form" onSubmit={onSubmitHandler}>
              <Form.Item label="First Name">
                {getFieldDecorator('firstName', {
                  initialValue: driver.firstName,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter First Name!',
                    },
                  ],
                })(
                  <Input
                    id="firstName"
                    key="firstName"
                    name="firstName"
                    placeholder="Enter First Name"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Last Name">
                {getFieldDecorator('lastName', {
                  initialValue: driver.lastName,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Name!',
                    },
                  ],
                })(
                  <Input
                    id="lastName"
                    key="lastName"
                    name="lastName"
                    placeholder="Enter Last Name"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Phone Number">
                {getFieldDecorator('phoneNumber', {
                  initialValue: driver.phoneNumber,
                  rules: [
                    {
                      required: true,
                      validator: validatePhone,
                      message: 'Please Enter A Valid Phone Number!',
                    },
                  ],
                })(
                  <Input
                    id="phoneNumber"
                    key="phoneNumber"
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  rules: [
                    {
                      required: true,
                      type: 'email',
                      message: 'Please Enter a Valid Email!',
                    },
                  ],
                })(
                  <Input
                    id="email"
                    key="email"
                    name="email"
                    placeholder="Enter email address"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Vehicle Make">
                {getFieldDecorator('vehicleMake', {
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Vehicle Make!',
                    },
                  ],
                })(
                  <Input
                    id="vehicleMake"
                    key="vehicleMake"
                    name="vehicleMake"
                    placeholder="Enter Vehicle Make"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Vehicle Model">
                {getFieldDecorator('vehicleModel', {
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Vehicle Model!',
                    },
                  ],
                })(
                  <Input
                    id="vehicleModel"
                    key="vehicleModel"
                    name="vehicleModel"
                    placeholder="Enter Vehicle Model"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Vehicle Color" required>
                {getFieldDecorator('vehicleColor', {
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Vehicle Color!',
                    },
                  ],
                })(
                  <Input
                    id="vehicleColor"
                    key="vehicleColor"
                    name="vehicleColor"
                    placeholder="Enter Vehicle Color"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Vehicle License Plate Number">
                {getFieldDecorator('vehicleLicensePlateNumber', {
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Vehicle License Plate Number!',
                    },
                  ],
                })(
                  <Input
                    id="vehicleLicensePlateNumber"
                    key="vehicleLicensePlateNumber"
                    name="vehicleLicensePlateNumber"
                    placeholder="Enter Vehicle License Plate Number"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Bank Name">
                {getFieldDecorator('bankName', {
                  initialValue: driver.bankName,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Bank Name!',
                    },
                  ],
                })(
                  <Input
                    id="bankName"
                    key="bankName"
                    name="bankName"
                    placeholder="Enter Bank Name"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Account Number">
                {getFieldDecorator('accountNumber', {
                  initialValue: driver.accountNumber,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Account Number!',
                    },
                  ],
                })(
                  <Input
                    id="accountNumber"
                    key="accountNumber"
                    name="accountNumber"
                    placeholder="Enter Account Number"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Routing Number">
                {getFieldDecorator('routingNumber', {
                  initialValue: driver.routingNumber,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Routing Number!',
                    },
                  ],
                })(
                  <Input
                    id="routingNumber"
                    key="routingNumber"
                    name="routingNumber"
                    placeholder="Enter Routing Number"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Location" required>
                <LocationSearchInput
                  location={location}
                  setLocation={setLocation}
                  setDriverLocation={setDriverLocation}
                  driver={driver}
                />
              </Form.Item>
              <Form.Item label="Status">
                {getFieldDecorator('status', {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(
                  <div className="statusbar">
                    <span>{driverStatus ? 'Active' : 'Inactive'}</span>
                    <Switch
                      checked={driverStatus}
                      onChange={onDriverStatusChange}
                      onColor="#36ae47"
                      onHandleColor="#36af47"
                      handleDiameter={15}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={7}
                      width={23}
                    />
                  </div>,
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : (
          <div>
            {/*<Icon className={styles.close} type="close" />*/}
            <div className={styles.avatarBlock}>
              <Avatar
                size={80}
                icon="user"
                style={{border: '1.5px solid red'}}
              />
            </div>
            <Form className="login-form" onSubmit={onSubmitHandler}>
              <Form.Item label="First Name">
                {getFieldDecorator('firstName', {
                  initialValue: driver.firstName,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter First Name!',
                    },
                  ],
                })(
                  <Input
                    id="firstName"
                    key="firstName"
                    name="firstName"
                    placeholder="Enter First Name1"
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Last Name">
                {getFieldDecorator('lastName', {
                  initialValue: driver.lastName,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Name!',
                    },
                  ],
                })(
                  <Input
                    id="lastName"
                    key="lastName"
                    name="lastName"
                    placeholder="Enter Last Name"
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Phone Number">
                {getFieldDecorator('phoneNumber', {
                  initialValue: driver.phoneNumber,
                  rules: [
                    {
                      required: true,
                      validator: validatePhone,
                      message: 'Please Enter A Valid Phone Number!',
                    },
                  ],
                })(
                  <Input
                    id="phoneNumber"
                    key="phoneNumber"
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  initialValue: driver.email,
                  rules: [
                    {
                      required: true,
                      type: 'email',
                      message: 'What is your email address?',
                    },
                  ],
                })(
                  <Input
                    id="email"
                    key="email"
                    name="email"
                    placeholder="Enter email address"
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Vehicle Make">
                {getFieldDecorator('vehicleMake', {
                  initialValue: driver.vehicleMake,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Vehicle Make!',
                    },
                  ],
                })(
                  <Input
                    id="vehicleMake"
                    key="vehicleMake"
                    name="vehicleMake"
                    placeholder="Enter Vehicle Make"
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Vehicle Model">
                {getFieldDecorator('vehicleModel', {
                  initialValue: driver.vehicleModel,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Vehicle Model!',
                    },
                  ],
                })(
                  <Input
                    id="vehicleModel"
                    key="vehicleModel"
                    name="vehicleModel"
                    placeholder="Enter Vehicle Model"
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Vehicle Color">
                {getFieldDecorator('vehicleColor', {
                  initialValue: driver.vehicleColor,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Vehicle Color!',
                    },
                  ],
                })(
                  <Input
                    id="vehicleColor"
                    key="vehicleColor"
                    name="vehicleColor"
                    placeholder="Enter Vehicle Color"
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Vehicle License Plate Number">
                {getFieldDecorator('vehicleLicensePlateNumber', {
                  initialValue: driver.vehicleLicensePlateNumber,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Vehicle License Plate Number!',
                    },
                  ],
                })(
                  <Input
                    id="vehicleLicensePlateNumber"
                    key="vehicleLicensePlateNumber"
                    name="vehicleLicensePlateNumber"
                    placeholder="Enter Vehicle License Plate Number"
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Bank Name">
                {getFieldDecorator('bankName', {
                  initialValue: driver.bankName,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Bank Name!',
                    },
                  ],
                })(
                  <Input
                    id="bankName"
                    key="bankName"
                    name="bankName"
                    placeholder="Enter Bank Name"
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Account Number">
                {getFieldDecorator('accountNumber', {
                  initialValue: driver.accountNumber,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Account Number!',
                    },
                  ],
                })(
                  <Input
                    id="accountNumber"
                    key="accountNumber"
                    name="accountNumber"
                    placeholder="Enter Account Number"
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Routing Number">
                {getFieldDecorator('routingNumber', {
                  initialValue: driver.routingNumber,
                  rules: [
                    {
                      required: true,
                      message: 'Please Enter Routing Number!',
                    },
                  ],
                })(
                  <Input
                    id="routingNumber"
                    key="routingNumber"
                    name="routingNumber"
                    placeholder="Enter Routing Number"
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Location" required>
                {getFieldDecorator('location', {
                  initialValue: driver.location
                    ? `${driver.location.addressOne}, ${driver.location.addressTwo}`
                    : '',
                  rules: [
                    {
                      required: false,
                      // message: 'Please Enter Location!',
                    },
                  ],
                })(
                  <Input
                    id="location"
                    key="location"
                    name="location"
                    onChange={text => {}}
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Status">
                {getFieldDecorator('status', {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(
                  <div className="statusbar">
                    <span>{driverStatus ? 'Active' : 'Inactive'}</span>
                    <Switch
                      checked={driverStatus}
                      onChange={onDriverStatusChange}
                      onColor="#36ae47"
                      onHandleColor="#36af47"
                      handleDiameter={15}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={7}
                      width={23}
                      disabled
                    />
                  </div>,
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  disabled={editDisabled}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form.create({name: 'editDriver'})(EditDriver);
