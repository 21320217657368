/* @flow */
import React from 'react';
// import {Card} from 'antd';
import {Link} from 'react-router-dom';
import * as styles from './style.module.scss';
import logo from '../../assets/bitmap.png';
import rightimg from '../../assets/loginbg.svg';
import {LogInForm} from '../../components';

type Props = {
  history: any,
  onStateChange: Function,
};

const Login = ({history, onStateChange}: Props) => {
  return (
    <div className={styles.loginMainSection}>
      <div className={styles.loginMainContent}>
        <div className={styles.loginMainLeft}>
          <img src={logo} alt={logo} className={styles.mainLogo} />
          <div className={styles.loginFormSection}>
            <div className={styles.loginFormSectionContent}>
              <h3>Login to your account</h3>
              <LogInForm onStateChange={onStateChange} history={history} />
              <p>
                By Logging in you accept our <Link to="#">Terms of Use</Link>{' '}
                and
                <Link to="#"> Privacy Policy</Link>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.loginMainRight}>
          <img src={rightimg} alt="rightimg" />
        </div>
      </div>
    </div>
  );
};

export default Login;
