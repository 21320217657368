const {REACT_APP_API_BASE_URL: apiBaseUrl} = process.env;

export const API_URLS = {
  dev: {
    FILE_UPLOAD_SIGNEDURL: `${apiBaseUrl}/file-upload/signedurl`,
    GET_ALL_DATA: `${apiBaseUrl}/data/bydate`,
    ADD_DRIVER: `${apiBaseUrl}/driver`,
    GET_ALL_DRIVERS: `${apiBaseUrl}/drivers`,
    EDIT_DRIVER: `${apiBaseUrl}/driver`,
    GET_DRIVER_SIGNUP_REQUESTS: `${apiBaseUrl}/driver/signup/requests`,
  },
};
