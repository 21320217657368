import * as React from 'react';
import {Route} from 'react-router-dom';

type Props = {
  component: any,
  authData?: Object,
};

class DefaultLayout extends React.Component<Props, {collapsed: boolean}> {
  render() {
    const {component: Component, authData, ...rest} = this.props;
    return (
      <Route
        {...rest}
        render={matchProps => (
          <div>
            <Component {...matchProps} {...this.props} />
          </div>
        )}
      />
    );
  }
}

export default DefaultLayout;
