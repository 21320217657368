import React, {useEffect} from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

type props = {
  initialAddress: string,
  location: string,
  setLocation: any,
  setDriverLocation: any,
  driver: Object,
};

const LocationSearchInput = ({
  initialAddress,
  location,
  setLocation,
  setDriverLocation,
  driver,
}: props) => {
  useEffect(() => {
    if (initialAddress && initialAddress !== '') {
      geocodeByAddress(initialAddress)
        .then(results => {
          if (results && results[0]) {
            getLatLng(results[0]).then(latLng => {
              setLocation(initialAddress);
              setDriverLocation({
                latitude: String(latLng.lat),
                longitude: String(latLng.lng),
                addressOne: initialAddress,
                addressTwo: '',
                type: 'driver_location',
              });
            });
          }
        })
        // eslint-disable-next-line no-console
        .catch(error => console.error('Error', error));
    }
  }, [initialAddress, setDriverLocation, setLocation]);

  const handleChange = location => {
    setLocation(location);
  };

  const handleSelect = suggestion => {
    setLocation(suggestion.description);
    geocodeByPlaceId(suggestion.placeId)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        setDriverLocation({
          latitude: String(latLng.lat),
          longitude: String(latLng.lng),
          addressOne: String(suggestion.formattedSuggestion.mainText),
          addressTwo: String(suggestion.formattedSuggestion.secondaryText),
          type: 'driver_location',
        });
      })
      // eslint-disable-next-line no-console
      .catch(error => console.error('Error', error));
  };

  return (
    <PlacesAutocomplete
      value={
        location !== ''
          ? location
          : driver
          ? `${driver.location.addressOne}, ${driver.location.addressTwo}`
          : ''
      }
      onChange={handleChange}
    >
      {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'location-search-input',
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                : {backgroundColor: '#ffffff', cursor: 'pointer'};
              const suggestionProps = getSuggestionItemProps(suggestion, {
                className,
                style,
              });
              return (
                <div
                  key={suggestion.id}
                  {...suggestionProps}
                  onClick={() => {
                    suggestionProps.onClick();
                    handleSelect(suggestion);
                  }}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
