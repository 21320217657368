/* @flow */
import * as React from 'react';
import {Menu, Icon} from 'antd';
import * as styles from './style.module.scss';

type props = {
  history: Object,
};

const SideBar = ({history}: props) => {
  const getDefaultSelectKey = () => {
    switch (history.location.pathname) {
      case '/':
        return ['1'];
      case '/drivers/request':
        return ['2'];
      default:
        return ['1'];
    }
  };
  return (
    <div className={styles.SideBarContainer}>
      <div className={styles.SideBarContent}>
        <Menu
          mode="inline"
          defaultSelectedKeys={getDefaultSelectKey()}
          onSelect={item => {
            if (item.key === '1') {
              history.push('/');
            } else if (item.key === '2') {
              history.push('/drivers/request');
            }
          }}
        >
          <Menu.Item key="1">
            <Icon type="team" />
            {/*<Icon type="team" onClick={() => history.push('/')} />*/}
          </Menu.Item>
          <Menu.Item key="2">
            <Icon type="usergroup-add" />
            {/*<Icon*/}
            {/*  type="usergroup-add"*/}
            {/*  onClick={() => history.push('/drivers/request')}*/}
            {/*/>*/}
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
};

export default SideBar;

// const {  Tabs  } = antd;

// const { TabPane } = Tabs;

// function callback(key) {
//   console.log(key);
// }

// const Demo = () => (
//   <Tabs defaultActiveKey="1" tabPosition="left" onChange={callback}>
//     <TabPane tab="Tab 1" key="1">
//       Content of Tab Pane 1
//     </TabPane>
//     <TabPane tab="Tab 2" key="2">
//       Content of Tab Pane 2
//     </TabPane>
//     <TabPane tab="Tab 3" key="3">
//       Content of Tab Pane 3
//     </TabPane>
//   </Tabs>
// );

// ReactDOM.render(<Demo />, mountNode);
