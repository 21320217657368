/* @flow */
import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import {Table, Button} from 'antd';
import moment from 'moment';
import * as styles from './style.module.scss';
import {Header} from '../../components';
import {SideBar} from '../../components';
import {getAllDriverSignupRequests} from '../../services/AdminService';

const columns = [
  {
    title: 'Add Driver',
    dataIndex: 'random1',
    // eslint-disable-next-line react/display-name
    render: (key, request) => (
      <>
        <Button
          type="primary"
          disabled={request.status !== 'contract_signature_completed'}
          onClick={() => {
            request.onAddDriver();
          }}
        >
          Add Driver
        </Button>
      </>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Report Created Date',
    dataIndex: 'reportCreatedDate',
  },
  {
    title: 'Make',
    dataIndex: 'make',
  },
  {
    title: 'Model',
    dataIndex: 'model',
  },
  {
    title: 'Color',
    dataIndex: 'color',
  },
  {
    title: 'license plate number',
    dataIndex: 'licenseplatenumber',
  },
];

const scroll = {x: 1500, y: 500};
// const rowSelection = {
//   onChange: (selectedRowKeys, selectedRows) => {
//     /* eslint-disable no-console */
//     console.log(
//       `selectedRowKeys: ${selectedRowKeys}`,
//       'selectedRows: ',
//       selectedRows,
//     );
//   },
// };

const getDriverRequestObject = request => ({
  key: request.id,
  requestId: request.id,
  name: `${request.firstName} ${request.lastName}`,
  firstName: request.firstName,
  lastName: request.lastName,
  email: request.email,
  phone: request.phoneNumber,
  address: request.address,
  ssn: request.ssn,
  make: request.make,
  model: request.model,
  color: request.color,
  licenseplatenumber: request.licensePlateNumber,
  consentToBackgroundCheck: request.consentToBackgroundCheck,
  status: request.status,
  bankName: request.bankName,
  accountNumber: request.accountNumber,
  routingNumber: request.routingNumber,
  reportCreatedDate:
    request.reportCreatedDate === true
      ? 'Not created'
      : moment(request.reportCreatedDate).format('MM/DD/YYYY'),
});

type props = {
  history: Object,
};

const DriverRequest = ({history}: props) => {
  // const selectionType = useState('checkbox');
  const [driverRequestsData, setDriverRequestsData] = useState();
  const signOut = () => {
    Auth.signOut()
      // eslint-disable-next-line no-console
      .then(data => console.log(data))
      // eslint-disable-next-line no-console
      .catch(err => console.log(err));
  };

  useEffect(() => {
    (async () => {
      const {driverSignupRequests} = await getAllDriverSignupRequests();
      setDriverRequestsData(
        driverSignupRequests.map(request => ({
          ...getDriverRequestObject(request),
          random1: Math.random(),
          onAddDriver: function() {
            history.push('/', getDriverRequestObject(request));
          },
        })),
      );
    })();
  }, [history]);

  return (
    <div className={styles.driverRequestContainer}>
      <Header signOut={signOut} />
      <div className={styles.driverRequestContent}>
        <SideBar history={history} />
        <div className={styles.driverRequestBody}>
          <div className={styles.headerSectionBox}>
            <h3 className={styles.driverHeaderText}>Driver Request</h3>
            {/*<Button type="primary">Verify</Button>*/}
          </div>
          <Table
            columns={columns}
            dataSource={driverRequestsData}
            scroll={scroll}
          />
        </div>
      </div>
    </div>
  );
};

export default DriverRequest;
